<template>
  <!-- Error page-->
  <div class="w-100 text-center">
    <h2 class="mb-2">Ei...</h2>
    <h2>Você não tem permissão para acessar esta página</h2>
    <p class="mb-2">Contate seu administrador</p>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { BLink, BImg, BButton } from 'bootstrap-vue'
  import { getHomeRouteForLoggedInUser } from '@/auth/utils'

  export default {
    components: {
      BLink,
      BImg,
      BButton,
    },
    data() {
      return {}
    },
    methods: {
      loginRoute() {
        const user = JSON.parse(localStorage.getItem('userData'))
        return getHomeRouteForLoggedInUser(user ? user.role : null)
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
